import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "bootstrap/dist/css/bootstrap.rtl.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "@mdi/font/css/materialdesignicons.min.css";

import "../src/assets/scss/main.scss";
import myMixin from "@/mixins/mixins";
import "./registerServiceWorker";

createApp(App).use(store).use(router).mixin(myMixin).mount("#app");
