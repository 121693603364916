import { createStore } from "vuex";
import auth from "./modules/authModule";
import getMovie from "./modules/MoviesModule";

export default createStore({
  modules: {
    auth,
    getMovie,
  },
});
