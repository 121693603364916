import {defineAsyncComponent} from "vue"
import { createRouter, createWebHistory } from "vue-router";
import store from "../store";

const Home = defineAsyncComponent(()=> import(/* webpackChunkName: "Home" */ "../views/PageHome"))
const PageNotFound = defineAsyncComponent(()=> import(/* webpackChunkName: "notFound" */ "../views/PageNotFound"))
const Login = defineAsyncComponent(()=> import(/* webpackChunkName: "Login" */ "../views/PageLogin"))
const Register = defineAsyncComponent(()=> import(/* webpackChunkName: "Register" */ "../views/PageRegister"))
const Profile = defineAsyncComponent(()=> import(/* webpackChunkName: "Profile" */ "../views/PageProfile"))
const Article = defineAsyncComponent(()=> import(/* webpackChunkName: "Article" */ "../views/article/Article"))
const AddArticle = defineAsyncComponent(()=> import(/* webpackChunkName: "AddArticle" */ "../views/article/Add"))
const SingleArticle = defineAsyncComponent(()=> import(/* webpackChunkName: "SingleArticle" */ "../views/article/Single"))
const Search = defineAsyncComponent(()=> import(/* webpackChunkName: "Search" */ "../views/PageSearch"))
const GenreListMovie = defineAsyncComponent(()=> import(/* webpackChunkName: "GenreListMovie" */ "../views/PageGenreListMovie"))
const SingleMovie = defineAsyncComponent(()=> import(/* webpackChunkName: "SingleMovie" */ "../views/PageSingleMovie"))
const GenreListTV = defineAsyncComponent(()=> import(/* webpackChunkName: "GenreListTV" */ "../views/PageGenreListTv"))
const SingleTv = defineAsyncComponent(()=> import(/* webpackChunkName: "SingleTv" */ "../views/PageSingleTv"))
const SingleSeason = defineAsyncComponent(()=> import(/* webpackChunkName: "SingleSeason" */ "../views/PageSingleSeason"))
const SingleEpisode = defineAsyncComponent(()=> import(/* webpackChunkName: "SingleEpisode" */ "../views/PageSingleEpisode"))
const SinglePerson = defineAsyncComponent(()=> import(/* webpackChunkName: "SinglePerson" */ "../views/PageSinglePerson"))
const CryptoCurrency = defineAsyncComponent(()=> import(/* webpackChunkName: "CryptoCurrency" */ "../views/PageCryptoCurrency"))


const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/404",
    alias: "/:pathMatch(.*)*",
    name: "notFound",
    component: PageNotFound
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { LoginRedirect: true },
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: { LoginRedirect: true },
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: { requireLogin: true },
  },
  {
    path: "/article",
    name: "Article",
    component: Article,
  },
  {
    path: "/add-article",
    name: "AddArticle",
    component: AddArticle,
    meta: { requireLogin: true },
  },
  {
    path: "/article/:slug",
    name: "SingleArticle",
    component: SingleArticle,
  },
  {
    path: "/search",
    name: "Search",
    component: Search,
  },
  {
    path: "/genre-movie/:id",
    name: "GenreListMovie",
    component: GenreListMovie,
  },
  {
    path: "/movie/:id",
    name: "SingleMovie",
    component: SingleMovie,
  },
  {
    path: "/genre-tv/:id",
    name: "GenreListTV",
    component: GenreListTV,
  },
  {
    path: "/tv/:id",
    name: "SingleTv",
    component: SingleTv,
  },
  {
    path: "/tv/:id/:season",
    name: "SingleSeason",
    component: SingleSeason,
  },
  {
    path: "/tv/:id/:season/:episode",
    name: "SingleEpisode",
    component: SingleEpisode,
  },
  {
    path: "/person/:id/",
    name: "SinglePerson",
    component: SinglePerson,
  },
  {
    path: "/crypto",
    name: "CryptoCurrency",
    component: CryptoCurrency,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
  if (to.matched.some((record) => record.meta.requireLogin)) {
    if (store.state.auth.isAuthentication) {
      next();
    } else {
      next("/login");
    }
  } else if (to.matched.some((record) => record.meta.LoginRedirect)) {
    if (!store.state.auth.isAuthentication) {
      next();
    } else {
      next("/profile");
    }
  } else {
    next();
  }
});

export default router;
