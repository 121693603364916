import apiAxios from "../../Api";
import router from "@/router";

const moviesModule = {
  namespaced: true,
  state: {
    api_key: "4fb16533dea4ee2177cebced4bfc004d",
    lang: "en-US",
    movieGenres: [],
    tvGenres: [],
    moviePopular: [],
    tvPopular: [],
    movieTopRating: [],
    popularPerson: [],
    ListMovieGenre: [],
    ListTVGenre: [],
    movie: [],
    imageMovieBackdrops: [],
    imageMoviePosters: [],
    tv: [],
    season: [],
    episode: [],
    episodeCrew: [],
    episodeGuestStars: [],
    person: [],
    personImage: [],
    filtered: [],
    filteredGenres: [],
    searchMovie: [],
    searchTv: [],
    trailersMovie: [],
    trailersTv: [],
    similarMovie: [],
    similarTv: [],
    rolePlayPerson: [],
    Page: 1,
    pages: {
      current: 1,
      totalPage: 0,
    },
    status: {
      movieGenresLoading: false,
      tvGenresLoading: false,
      filteredGenresLoading: false,
      moviePopularLoading: false,
      tvPopularPopularLoading: false,
      movieTopRatingPopularLoading: false,
      popularPersonLoading: false,
      ListMovieGenreLoading: false,
      ListTVGenreLoading: false,
      movieShowLoading: false,
      imageMovieShowLoading: false,
      tvShowLoading: false,
      seasonShowLoading: false,
      episodeShowLoading: false,
      personShowLoading: false,
      personImageShowLoading: false,
      filteredShowLoading: false,
      searchMovieLoading: false,
      searchTvLoading: false,
      trailersMovieLoading: false,
      trailersTvLoading: false,
      similarMovieLoading: false,
      similarTvLoading: false,
      rolePlayPersonLoading: false,
    },
  },
  getters: {
    imageMovieBackdropsFilterd(state) {
      return state.imageMovieBackdrops.splice(0, 8);
    },
    imageMoviePostersFilterd(state) {
      return state.imageMoviePosters.splice(0, 6);
    },
    trailersMovieFilterd(state) {
      return state.trailersMovie.splice(0, 2);
    },
    trailersTvFilterd(state) {
      return state.trailersTv.splice(0, 2);
    },
    similarMovieFilterd(state) {
      return state.similarMovie.splice(0, 4);
    },
    similarTvFilterd(state) {
      return state.similarTv.splice(0, 4);
    },
  },
  mutations: {
    setMovieGenres(state, response) {
      state.status.movieGenresLoading = true;
      state.movieGenres = response.data.genres;
    },
    setTvGenres(state, response) {
      state.status.tvGenresLoading = true;
      state.tvGenres = response.data.genres;
    },
    setFilteredGenres(state, response) {
      state.status.filteredGenresLoading = true;
      state.filteredGenres = response.data.genres;
    },
    setMoviePopular(state, response) {
      state.status.moviePopularLoading = true;
      state.moviePopular = response.data.results;
    },
    setTvPopular(state, response) {
      state.status.tvPopularPopularLoading = true;
      state.tvPopular = response.data.results;
    },
    setMovieTopRating(state, response) {
      state.status.movieTopRatingPopularLoading = true;
      state.movieTopRating = response.data.results;
    },
    setPopularPerson(state, response) {
      state.status.popularPersonLoading = true;
      state.popularPerson = response.data.results;
    },
    setListMovieGenre(state, response) {
      state.pages.current = response.payload.page;
      state.pages.totalPage =
        response.response.data["total_pages"] < 500
          ? response.response.data["total_pages"]
          : 500;
      state.status.ListMovieGenreLoading = true;
      state.ListMovieGenre = response.response.data.results;
    },
    setListTvGenre(state, response) {
      state.pages.current = response.payload.page;
      state.pages.totalPage =
        response.response.data["total_pages"] < 500
          ? response.response.data["total_pages"]
          : 500;
      state.status.ListTVGenreLoading = true;
      state.ListTVGenre = response.response.data.results;
    },
    setMovie(state, response) {
      state.status.movieShowLoading = true;
      state.movie = response.data;
    },
    setResetMovie(state) {
      state.status.movieShowLoading = false;
      state.movie = [];
    },
    setImageMovie(state, response) {
      state.status.imageMovieShowLoading = true;
      state.imageMovieBackdrops = response.data.backdrops;
      state.imageMoviePosters = response.data.posters;
    },
    setResetImageMovie(state) {
      state.status.imageMovieShowLoading = false;
      state.imageMovie = [];
    },
    setTv(state, response) {
      state.status.tvShowLoading = true;
      state.tv = response.data;
    },
    setResetTv(state) {
      state.status.tvShowLoading = false;
      state.tv = [];
    },
    setSeason(state, response) {
      state.status.seasonShowLoading = true;
      state.season = response.data;
    },
    setEpisode(state, response) {
      state.status.episodeShowLoading = true;
      state.episode = response.data;
      state.episodeCrew = response.data.crew;
      state.episodeGuestStars = response.data.guest_stars;
    },
    setPerson(state, response) {
      state.status.personShowLoading = true;
      state.person = response.data;
    },
    setPersonImage(state, response) {
      state.status.personImageShowLoading = true;
      state.personImage = response.data.profiles;
    },
    setFiltered(state, response) {
      state.pages.current = response.payload.page;
      state.pages.totalPage =
        response.response.data["total_pages"] < 500
          ? response.response.data["total_pages"]
          : 500;
      state.status.filteredShowLoading = true;
      state.filtered = response.response.data.results;
    },
    setResetFilter(state) {
      state.pages.current = 1;
      state.pages.totalPage = 0;
      state.status.filteredShowLoading = false;
      state.filtered = [];
    },
    setResetAdvanceSearch(state) {
      state.pages.current = 1;
      state.pages.totalPage = 0;
      state.status.filteredShowLoading = false;
      state.filtered = [];
      state.filteredGenres = [];
    },
    setSearchMovie(state, response) {
      state.status.searchMovieLoading = true;
      state.searchMovie = response.data.results;
    },
    setResetSearchMovie(state) {
      state.status.searchMovieLoading = false;
      state.searchMovie = [];
    },
    setSearchTv(state, response) {
      state.status.searchTvLoading = true;
      state.searchTv = response.data.results;
    },
    setResetSearchTv(state) {
      state.status.searchTvLoading = false;
      state.searchTv = [];
    },
    setTrailersMovie(state, response) {
      state.status.trailersMovieLoading = true;
      state.trailersMovie = response.data.results;
    },
    setResetTrailersMovie(state) {
      state.status.trailersMovieLoading = false;
      state.trailersMovie = [];
    },
    setTrailersTv(state, response) {
      state.status.trailersTvLoading = true;
      state.trailersTv = response.data.results;
    },
    setResetTrailersTv(state) {
      state.status.trailersTvLoading = false;
      state.trailersTv = [];
    },
    setSimilarMovie(state, response) {
      state.status.similarMovieLoading = true;
      state.similarMovie = response.data.results;
    },
    setResetSimilarMovie(state) {
      state.status.similarMovieLoading = false;
      state.similarMovie = [];
    },
    setSimilarTV(state, response) {
      state.status.similarTvLoading = true;
      state.similarTv = response.data.results;
    },
    setResetSimilarTV(state) {
      state.status.similarTvLoading = false;
      state.similarTv = [];
    },
    setRolePlayPerson(state, response) {
      state.status.rolePlayPersonLoading = true;
      state.rolePlayPerson = response.data.cast;
    },
  },
  actions: {
    async fetchData(context, payload) {
      try {
        const response = await apiAxios.get(payload.query);
        context.commit(payload.setName, response);
      } catch (error) {
        let { status } = error.response;
        if (status === 404) {
          router.push("/404");
        }
        console.log("error in catch fetchData");
      }
    },
    async fetchDataPagination(context, payload) {
      try {
        const response = await apiAxios.get(payload.query);
        context.commit(payload.setName, { response, payload });
      } catch (error) {
        let { status } = error.response;
        if (status === 404) {
          router.push("/404");
        }
        console.log("error in catch fetchData");
      }
    },
  },
};
export default moviesModule;
